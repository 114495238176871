import i18n from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { cl } from '../../utils/general';
import style from './Footer.module.css';

const BASE_URL = 'https://prostopay.net/';
const langList = ['uk', 'en', 'ka', 'it', 'ro'];

const Footer = () => {
    const { t } = useTranslation();
    const [showLanguagePop, setShowLanguagePop] = useState(false);

    return (
        <div className={style.footer}>
            <div className='footerLogoBox'>
                <a
                    href={BASE_URL}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={style.footerLogo}
                >
                    <img
                        alt='ProstoPay logo'
                        src='/logo_bottom.svg'
                        className={style.logoSVG}
                    />
                </a>
            </div>
            <div className={style.footerTextBox}>
                <a href={BASE_URL} target='_blank' rel='noopener noreferrer'>
                    <span className={style.hrefText}>{t('Copyright')}</span>
                </a>
                <a
                    href={`${BASE_URL}docs/termsofuse.html`}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <span className={style.hrefText}>{t('Terms_of_use')}</span>
                </a>
            </div>
            <div className={style.footerLangBox}>
                {showLanguagePop && (
                    <ul className={style.languageList}>
                        {langList.map((l) => (
                            <li
                                key={l}
                                className={
                                    l === i18n.language ? style.activeLang : ''
                                }
                                onClick={() => {
                                    cl('click', l, i18n.language);
                                    i18n.changeLanguage(l);
                                    setShowLanguagePop(false);
                                }}
                            >
                                {t(`Language.${l.toUpperCase()}`)}
                            </li>
                        ))}
                    </ul>
                )}
                <div className={style.languageButton}>
                    <a
                        onClick={() => {
                            cl('click', showLanguagePop);
                            setShowLanguagePop(!showLanguagePop);
                        }}
                    >
                        <img
                            height='30px'
                            src='/globe.png'
                            alt='Language change'
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
