import styled from 'styled-components';
import { CookieIcon } from './CookieIcon';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import style from './CookieBanner.module.css';

const AcceptButton = styled.button`
  background: linear-gradient(270deg, #5c81ed 5.76%, #1b3f92 92.6%);
  color: white;
  border-radius: 18px;
  padding: 10px, 20px, 10px, 20px;
  gap: 10px;
  margin-right: 5px;
  width: 105px;
  height: 37px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
  margin-top: 5px;
`;
const CookieButton = styled.button`
  background-color: #dbe3f5 !important;
  color: #1b3f92;
  border-radius: 18px;
  margin-right: 5px;
  width: 100%;
  height: 37px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  text-transform: inherit;
  margin-top: 0;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CookieBox = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 5px;
  width: 100%;
`;

const InsideAcceptDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const PrivacyPolicyLink = ({ href, children }: any) => (
  <Link href={href} target="_blank" rel="noopener">
    {children}
  </Link>
);

const CookieBanner = (props: any) => {
  const { handleClick, id, open, anchorEl, handleClose, onSubmitCookie } =
    props;
  const { t } = useTranslation();

  return (
    <CookieBox>
      <CookieButton onClick={handleClick}>
        <CookieIcon />
        {t('Cookies Policy')}
        <ArrowDropDownIcon />
      </CookieButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          sx: { backgroundColor: '#dbe3f5' },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <IconButton className={style.CloseButton} onClick={handleClose}>
          <CancelRoundedIcon />
        </IconButton>
        <Typography className={style.TypographyBox}>
          <Typography className={style.TitleText}>
            {t('We value your privacy')}
          </Typography>
          <Typography>
            {t(`Part 1`)}{' '}
            <PrivacyPolicyLink href="https://prostopay.net/docs/privacypolicy.html">
              {t('Privacy Policy')}
            </PrivacyPolicyLink>
            {t(`Part 2`)}{' '}
            <PrivacyPolicyLink href="https://prostopay.net/docs/termsofuse.html">
              {t('Terms of Use')}
            </PrivacyPolicyLink>{' '}
            {t(`Part 3`)}{' '}
            <PrivacyPolicyLink href="https://prostopay.net/docs/privacypolicy.html">
              {t('Privacy Policy')}
            </PrivacyPolicyLink>
          </Typography>
        </Typography>
        <InsideAcceptDiv>
          <AcceptButton onClick={onSubmitCookie}>{t('Accept')}</AcceptButton>
        </InsideAcceptDiv>
      </Popover>
      <AcceptButton onClick={onSubmitCookie}>{t('Accept')}</AcceptButton>
    </CookieBox>
  );
};

export default CookieBanner;